import React from "react";
import './scss/App.scss';
import Home from "./pages/home";
import Shop from "./pages/Shop";

import  { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserTemp from "./pages/userTempConfirm";

function App() {
  
  return (

    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id/:name" element={<Shop />} />
        <Route path="/controlUserTemporary/:shop/:token" element={<UserTemp />} />
      </Routes>
    </Router>

  );
}

export default App;