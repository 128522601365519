import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';

export default function Shop() {
    const { id } = useParams();
    const [ loader, setLoader ] = useState(true);
    const [ shop, setShop ] = useState({
        name: '',
        background: '',
        firstname: ''
    });

    useEffect(() => {
      
        console.log(id)
        if (id) {
            fetchShop(id);
        }
    }, []);

    const fetchShop = (id) => {
        axios.get(`https://lockeed.fr/api/shop/${id}/details`)
        .then( response => {
            console.log(response.data)
            const { name, background_image } = response.data[0];
            const { avatar, firstname } = response.data[0].users[0];
            setShop({ name: name, background: background_image, avatar: avatar, firstname: firstname});
            setLoader(false);
        } )
        .catch( e => console.log(e))
    }

    return (
        <>
        {
            loader ? (
                <div className={`loader-container`}>
                  <img src='../BLUE_SHORT.svg' />
                </div>
             ) : (
                <header className="header-shop" style={{backgroundImage:`url(https://lockeed.fr/uploads/images/backgrounds/${shop.background})`}}>
                    <h1>{ shop.name }</h1>
                    <img className="header-shop-avatar" src={ shop.avatar !== null ? shop.avatar : "https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" } />
                </header>
            )
        }
        </>
    )
}