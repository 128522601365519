import React, { useEffect, useState } from "react";
import { HeaderFormUserTemp } from "../components/Header";
import Footer from "../components/Footer";
import { InputCheckboxForm, InputForm, LabelForm, SelectorForm } from "../components/formGenerator";
import axios from "axios";
import { useParams } from "react-router-dom";
import dns from "../components/config";
import { UAParser } from "ua-parser-js";

export default function UserTemp(){
    const { shop,token } = useParams();

    const [ systemInfo, setSystemInfo ] = useState(null);
    const [location, setLocation] = useState({ city: "", country: "" });
    const [ user, setUser ] = useState(false);
    const [ loader, setLoader ] = useState(true);
    const [ statusControlToken, setStatusControlToken ] = useState(null);
    const [ shopId, setShopId ] = useState(null);
    const [ shopName, setShopName ] = useState(null);
    const [ civility, setCivility ] = useState(null);
    const [ password, setPassword ] = useState(null);
    const [ passwordConfirm, setPasswordConfirm ] = useState(null);
    const [ validCGV, setValidCGV ] = useState(false);
    const [ formValid, setFormValid ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ color, setColor ] = useState(null);

    useEffect(() => {
        controlTokenUser();
        const parser = new UAParser();
        const result = parser.getResult();
        setSystemInfo({
            device: result.device.type == undefined ? "inconnu" : result.device.type,
            os: result.os.name,
            osVersion: result.os.version,
            browser: result.browser.name,
            browserVersion: result.browser.version,
          });
          country();
    }, []);

    const controlTokenUser = () => {
        axios.get(`${dns()}/api/controlUserTokenTemporary/${shop}/${ token }`)
        .then( r => {
            if (r.status === 200) {
                setStatusControlToken(true);
                setLoader(false);
                setShopId(r.data[0]);
                setShopName(r.data[1]);
                setUser(r.data[2]);
            }
        
        } )
        .catch( r => {
            setStatusControlToken(false);
            setLoader(false);
        } )
    }

    const country = async () => {
        try {
          const response = await fetch("http://ip-api.com/json/");
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setLocation({
            city: data.city || 'inconnu',
            country: data.country || 'inconnu',
          });
        } catch (error) {
          console.error("Erreur de géolocalisation :", error);
          setLocation({
            city: 'inconnu',
            country: 'inconnu',
          });
        }
      };
      

    const sendValidAccountUser = (e) => {
        e.preventDefault();
        
        if(civility != null && password != null && passwordConfirm != null && validCGV != false) {
           

            if( password != passwordConfirm ) {
                resetError();
                setPassword(null);
                setPasswordConfirm(null);
            } else {
                setLoader(true);
  
                axios.put(`${dns()}/api/validateUserTemporary`,{
                    shop : shopId,
                    token : token,
                    civility : civility,
                    user : user,
                    system : systemInfo,
                    password : password,
                    location : location
                })
                .then( r => {
                    if(r.status == 200) {
                        setFormValid(true);
                        setLoader(false);
                    }
                } )
                .catch((error) => console.error("Erreur lors du traitement de la demande :", error));
            } 
        } 
        if  (validCGV == false) {
            setColor(true);
        }
    }

    const resetError = () => {
        setError("les mots de passe ne correspondent pas.");
        setTimeout(() => setError(false), 3000);
    }

    return(
        <>
            <HeaderFormUserTemp />
            <main>
                {
                    loader ? (
                        <div className={`loader-container`}>
                            <img src='../../logo_lockeed.jpeg' />
                      </div>
                    ) : (
                    statusControlToken ? (
                    formValid ? 
                    (
                        <>
                            <h2>Votre demande a bien été traité, vous pouvez dès maintenant vous connecter sur votre application Lockeed</h2>
                        </>
                    )
                    :
                    (
                        <form>
                        <h2>Vous êtes invité par { shopName }</h2>
                        <p>Bonjour, nous sommes ravis de vous inviter à rejoindre notre nouvelle plateforme de réservation en ligne, Lockeed, afin de profiter pleinement de ses nombreux avantages.</p>
                        <p>Conformément à la règlementation en vigueur, si vous ne souhaitez pas rejoindre notre plateforme, vos informations personnels renseigné par { shopName } seront supprimer dans un délais de 48h minimum.</p>
                        <div className="form-container">
                            <LabelForm label={"Vous êtes"} />
                            <SelectorForm  setter={setCivility} getter={civility}  name={"Sélectionnez une option"}/>
                        </div>
                        <div className="form-container">
                            <LabelForm label={"Votre mot de passe"} />
                            <InputForm name={"Votre mot de passe"} setter={setPassword} getter={password} type={"password"} placeholder={"Renseigner votre mot de passe"} />
                        </div>
                        <div className="form-container">
                            <LabelForm label={"Confirmation de votre mot de passe"} />
                            {
                                error && <div className={`error-container ${ error && "error-message " }`}>{ error }</div>
                            }
                            <InputForm name={"Confirmation de votre mot de passe"} setter={setPasswordConfirm} getter={passwordConfirm} type={"password"} placeholder={"Renseigner à nouveau votre mot de passe"} />
                        </div>
                        <div className="form-container-row">
                            <InputCheckboxForm getter={validCGV} setter={setValidCGV}  />
                            <LabelForm label={"Je confirme avoir pris connaissance des conditions générales de ventes"} cgv={true} color={color} />
                        </div>
                        <button onClick={ sendValidAccountUser }>Finaliser mon compte</button>
                    </form>
                    )) : (
                        <>
                            <h2>Cette page n'existe pas</h2>
                        </>
                    ))
                }
               
            </main>
            <Footer />
        </>
    )
}