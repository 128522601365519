import React, { useEffect, useState } from "react";
import '../scss/App.scss';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Search from '../components/Search';
import Background from "../components/background";
import axios from "axios";

function Home() {
  const [ loader, setLoader ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ openSearch , setOpenSearch ] = useState(false);
  const [ shops , setShops ] = useState([]);
  const [ messageActiv, setMessageActiv ] = useState(false);
  const [ background, setBackground ] = useState('');

  useEffect(() => {
    setBackground(Background()) ;
    open();
  }, [])

  const fetchSearch = (e) => {
    e.preventDefault();
    setLoader(true);
    
    axios.post('https://lockeed.fr/api/shop/search', {
        search: search
    })
    .then(response => { 
        response.data[1].length > 0 ? setShops(response.data[1]) : setMessageActiv(true);
        //setShops(response.data[1]) ; 
        setOpenSearch(true); 
        setLoader(false) ; })
    .catch(e => { 
        console.log(e); 
        setLoader(false) ; 
        setOpenSearch(true); 
    })
  }

  const open = () => {
    setLoader(true);
  }

  return (
    
    <div className="app" style={{backgroundImage: `url(${ background })`}}>
      {
        loader ? (
            <div className={`loader-container`}>
              <img src='../../logo_lockeed.jpeg' />
              <h1>On arrive bientôt, patience !</h1>
            </div>
         ) : (
            <>
              <Header />
              <Search 
                loader={ setLoader } 
                setSearch={ setSearch } 
                search={ search } 
                fetchSearch={ fetchSearch } 
                openSearch={ openSearch } 
                shops={ shops }
                message={ messageActiv }
              />
              <Footer />
            </>
      )}
    </div>
  );
}

export default Home;