import React from "react";
import { Link } from "react-router-dom";

export default function Search({ setSearch, search, fetchSearch, openSearch, shops, message }) {

    return (
        <div className="search-container">
            <form>
                <input type="text" value={search} placeholder="Rechercher un pro" onChange={ e => setSearch(e.target.value) } />
                <button onClick={  fetchSearch  } disabled={ `${ search.length === 0 ? "disabled" : "" }` } >Recherche</button>
            </form>
            <div className={`response-container  ${ openSearch && message === false ? "openSearch" : "" }`}>
                {
                    shops !== undefined ? shops?.map( s => {
                        return (
                            <Link key={s.id} className="card-shop" to={`/${s.id}/${s.shop_name}`}>
                                <img src={`https://lockeed.fr/uploads/images/backgrounds/${s.background_image}`} alt="background pro" />
                                <div className="card-info">
                                    <h2>{ s.shop_name }</h2>
                                    <p>{ s.address } { s.city }</p>
                                </div>

                            </Link>
                        )
                    } )
                    : ""
                }

            </div>
            {
                message ? noShop() : ""
            }
        </div>
 
    )
}

function noShop() {

    return (
        <div>
            <p>Pas de boutique trouvé</p>
        </div>
    )
    
}