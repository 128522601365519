export function LabelForm({label, color, cgv}) {
    return (
        <>
            <label className={`form-label ${color ? "red" : ""}`} for={label} >{ label } {cgv ? <a href="https://lockeed.fr/cgv">condition générales d'utilisation</a> : ""}</label>
        </>
        
    )
}

export function InputForm({name, type, setter, getter, placeholder, disabled = false}) {
    return (
        <input 
            className={"form-input"}
            for={name} 
            onChange={ e => setter( e.target.value ) } 
            type={type} 
            value={getter != null ? getter : ""}
            placeholder={placeholder}
            disabled={disabled}  
        />
    )
}

export function InputCheckboxForm({name, setter,getter, disabled = false}) {
    return (
        <input 
        className={`form-input `}
        for={name} 
        onChange={ e => setter( e.target.checked ) } 
        type={"checkbox"} 
        checked={getter}
        disabled={disabled}  
    />
    )
}

export function SelectorForm({name, setter, getter, disabled = false}) {
    return (
        <select className={"form-input"} onChange={e =>  setter(e.target.value)}>
            <option value="" >{name}</option>
            <option value="1">Un homme</option>
            <option value="2">Une femme</option>
        </select>
    )
}