import { useState } from "react";

function Header() {
    const [ toggle, setToggle ] = useState(null);
    const [ sign, setSign ] = useState(null);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmeEmail, setConfirmeEmail ] = useState('');

    const setMenu = (option) => {
        setToggle(!toggle);
        setSign(option);
    }

    return (
        <header>
            <h1><img className="logo" src="BLUE_LONG.svg" alt="lockeed" /></h1>
            <nav>
                <ul className={`menu ${ toggle ? "stop" : "" }`}>
                    <li className="signin"><div onClick={ () => { setMenu(true) } }>Inscription</div></li>
                    <li className="signin"><div onClick={ () => setMenu(false) }>Connection</div></li>
                </ul>
            </nav>
            <div className={`sign-container ${ toggle ? "open" : "" }`}>
                <form>
                    <h2>Inscription</h2>
                    {
                        sign && (
                            <>
                                <label>Votre prénom et nom</label>
                                <input type="text" onChange={ e => setName(e.target.value) } />
                            </>
                        )
                    }
                    <label>{ sign ? "Votre email" : "Email" }</label>
                    <input type="email" onChange={ e => setEmail(e.target.value) } />
                    <label>{ sign ? "Votre mot de passe" : "Mot de passe"}</label>
                    <input type="password" onChange={ e => setPassword(e.target.value) } />
                    {
                        sign && (
                            <>
                                <label>Confirmer votre mot de passe</label>
                                <input type="password" onChange={ e => setConfirmeEmail(e.target.value) } />
                            </>
                        )
                    }
                    <button>{ sign ? "M'inscrire" : "Connexion" }</button>
                    <div onClick={ () => setSign(!sign) }>{ sign ? "Déjà inscrit ?" : "M'inscrire" }</div>
                </form>
            </div>
        </header>
    )
}

export default Header;

export function HeaderFormUserTemp() {
    
    return (
        <header className="header-user-temp">
            <div className="header-user-temp-menu-container">
                <h1>Lockeed</h1>
                <nav>
                    <ul>
                        
                    </ul>
                </nav>
            </div>
            
        </header>
    )
}
